
<div mdbModal #addressFromMap="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 9999;">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body">
        <div #map id="map" class="map"></div>
        <div>
          <h3>{{util.translate('Set patient details & location')}}</h3> 
          <div class="borders">
            <p class="address">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              {{ (address.length>50)? (address | slice:0:50)+'..':(address) }}
            </p>
            <p class="changeBtn" mdbWavesEffect (click)="changeAddress()">{{util.translate('Change')}}</p>
          </div>
          <div *ngIf="addressSelected === true" class="row"> 
            <div class="md-form col-6">
              <label for="house"> {{util.translate('HOUSE / FLAT NO ')}} <span class="red_mrk">*</span> </label>
              <input id="house" style="height: auto !important;" [(ngModel)]="house" class="form-control" type="text"
                mdbInput>
              
            </div>
            <div class="md-form  col-6" >
              <label for="landmark"> {{util.translate('LANDMARK')}} </label>
              <input id="landmark" style="height: auto !important;" [(ngModel)]="landmark" class="form-control"
                type="text" mdbInput>
              
            </div>
            <div class="md-form col-6">
              <label for="pincode"> {{util.translate('Pincode' )}} <span class="red_mrk">*</span></label>
              <input id="pincode" style="height: auto !important;" [(ngModel)]="pincode" class="form-control"
                type="text" mdbInput>
              
            </div>
            <div class="md-form col-6">
              <label for="pincode"> {{util.translate('Address Type')}} </label>
              <select class="browser-default custom-select" [(ngModel)]="title" class="form-control form-select">
                <option value="home"> {{util.translate('HOME')}} </option>
                <option value="work"> {{util.translate('WORK')}} </option>
                <option value="other"> {{util.translate('OTHER')}} </option>
              </select>
              
            </div>
 
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Patient Name <span class="red_mrk">*</span> </div>
              <input type="text" placeholder="Patient Name" [(ngModel)]="patient_name" class="form-control" >
              
            </div>
            <div class="md-form col-6">
                <div style="text-align: left;" class="ng-star-inserted"> Mobile Number</div>
                <input type="Mobile" placeholder="Mobile Number" [(ngModel)]="patient_mobile" class="form-control" >
                
            </div> 
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Email id </div>
              <input type="email" placeholder="Email id" [(ngModel)]="patient_email" class="form-control" >
              
            </div>
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Date of birth </div>
                <input type="date" placeholder="Date of birth*" [(ngModel)]="patient_dob"   class="form-control" max="{{sdate}}">
                
              </div> 
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Select Gender <span class="red_mrk">*</span> </div>
                      <select placeholder="Gender" [(ngModel)]="patient_gender"  class="form-control form-select">
                        <option selected="" disabled="" value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      
            </div>
            <div class="md-form col-6"> 
              <div style="text-align: left;" class="ng-star-inserted"> Select Relation <span class="red_mrk">*</span> </div>
                      <select placeholder="Relation" [(ngModel)]="patient_relation" class="form-control form-select">
                          <option value="" selected="" disabled="">Select Relation</option>
                          <option value="Relation">Self</option>
                          <option value="Relation">Relation</option>
                          <option value="Spouse">Spouse</option>
                          <option value="Daughter">Daughter</option>
                          <option value="Son">Son</option>
                          <option value="Father">Father</option>
                          <option value="Family">Family</option>
                          <option value="Mother">Mother</option>
                      </select>
                      
              </div>  
                         


          </div>
        </div>
        <div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" *ngIf="addressSelected === false" mdbBtn color="" class="btn-primary" mdbWavesEffect
          (click)="chooseFromMaps()">
          {{util.translate('Confirm and Proceed')}}
        </a>
        <a type="button" *ngIf="addressSelected === true && editClicked ===false" mdbBtn color=""
          class="btn-primary" mdbWavesEffect (click)="addAddress()">
          {{util.translate('Add Address')}}
        </a>
        <a type="button" *ngIf="editClicked ===true" mdbBtn color="" class="btn-primary" mdbWavesEffect
          (click)="editMyAddress()">
          {{util.translate('Update')}}
        </a>
        <a type="button" mdbBtn color="" outline="true" class="btn-primary" mdbWavesEffect data-dismiss="modal"
          (click)="addressFromMap.hide()"> {{util.translate('Cancel')}} </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #changedPlace="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 9999;">
  <div class="modal-dialog modal-lg" role="document">
    <!--Content-->
    <div class="modal-content">

      <!--Body-->

      <div class="modal-body">
        <div class="headers">
          <p class="text">{{util.translate('Search Location')}}</p>
          <mdb-icon fas mdbWavesEffect (click)="changedPlace.hide();addressFromMap.show()" icon="times-circle">
          </mdb-icon>
        </div>
        <div class="md-form">
          <input id="cnum" style="height: auto !important;" [(ngModel)]="query" [placeholder]="util.translate('Search')"
            class="form-control" type="text" mdbInput (ngModelChange)="onSearchChange($event)">
        </div>
        <div class="addressList">
          <div class="select_addr" *ngFor="let item of autocompleteItems1" (click)="selectSearchResult1(item)">
            <mdb-icon fas icon="map-marker-alt" style="color: gray; margin-right: 10px;"></mdb-icon>
            {{ item.description }}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- <div class="container back_image" [style.backgroundImage]="'url(assets/imgs/banner.jpg)'"
  *ngIf="util.deviceType==='desktop'">
  <div class="overlay">
    <div class="profiles">
      <div class="cover" [ngStyle]="{'background-image':'url('+ getProfile()+'),url(assets/icon.png)'}"></div>
      <div class="user_details" *ngIf="util.userInfo">
        <label class="header">{{util.userInfo.first_name +' ' +util.userInfo.last_name}} </label>
        <label class="details">{{util.userInfo.email}} </label>
        <label class="details">{{util.userInfo.country_code +' ' +util.userInfo.mobile}} </label>
      </div>
    </div>
    <div class="more_section">
      <div class="btn btn-primary" mdbWavesEffect (click)="editProfileClick = true">
        {{util.translate('Edit Profile')}}
      </div>
    </div>
  </div>
</div> -->

<div class="container main_div">
  <div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12" *ngIf="util.deviceType==='desktop'">
      <p class="head">{{util.translate('User Dashboard')}}</p>
      <div [ngClass]="tabId ==='dashboard' ?'active_btn':'normal_btn'" (click)="openLink('dashboard')">
        {{util.translate('Dashboard')}}</div>
      <div [ngClass]="tabId ==='profile' ?'active_btn':'normal_btn'" (click)="openLink('profile')">
        {{util.translate('Profile')}}</div>
      <div [ngClass]="tabId ==='order' ?'active_btn':'normal_btn'" (click)="openLink('order')">
        {{util.translate('Order History')}}</div>
      <!-- <div [ngClass]="tabId ==='address' ?'active_btn':'normal_btn'" (click)="openLink('address')">
        {{util.translate('Patient List')}}</div> -->
      <div [ngClass]="tabId ==='testreport' ?'active_btn':'normal_btn'" (click)="openLink('report')">
        {{util.translate('Test Report')}}</div>
    </div>
    <div class="col-md-9 col-sm-6 col-xs-12">
      <p class="header" *ngIf="tabId ==='profile'">{{util.translate('User Informations')}}</p>
      <p class="header" *ngIf="tabId ==='order'">{{util.translate('Order History')}}</p>
      <!-- <p class="header" *ngIf="tabId ==='address'">{{util.translate('Patient List')}}</p> -->
      <p class="header" *ngIf="tabId ==='report'">{{util.translate('Test Report')}}</p>

      <div *ngIf="tabId ==='dashboard'">
        <div class="edit_profile">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-12">
              <div class="row">
                
                <div class="row" style="height: 50px; text-align: right;">
                  <div class="col-md-12" > <button  (click)="addNewAddress()" class="but btn-primary-sm">+ Add New Patient</button></div>
                
                </div>
                 

                <div class="brdblue col-md-4 item pt-15" *ngFor="let item of myaddress">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Patient : {{item.name}} </h5>
                          <p>Address : {{item.title}} - {{item.house}} {{item.landmark}} {{item.address}} {{item.pincode}}</p> 
                          <p>DOB : {{item.dob}} <br>Gender : {{item.gender}} <br>Relation : {{item.relation}} <br>E-Mail : {{item.email}} <br>Mobile : {{item.mobile}}</p>

                          <p><span class="editBtn" (click)="editAddress(item)" mdbWavesEffect>{{util.translate('Edit')}}</span>  
                          <!-- <span class="delete" (click)="deleteAddress(item)" mdbWavesEffect>{{util.translate('Delete')}}</span> -->
                         </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-6 col-xs-12 address_card" *ngFor="let item of dummyAddress">
                    <p class="title">
                      <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                    </p>
                    <p class="full_address">
                      <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'160px'}"> </ngx-skeleton-loader>
                    </p>
                    <div class="btns">
                      <label class="editBtn" mdbWavesEffect>
                        <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                      </label>
                      <label class="delete" mdbWavesEffect>
                        <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                      </label>
                    </div>
                  </div>
                </div>


                <div class="col-lg-2" style="height: 200px;"></div>

                 
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
      </div>

      <div *ngIf="tabId ==='profile'">
        <div class="edit_profile">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-12" *ngIf="util.userInfo">

                  <div class="round_image"
                    [ngStyle]="{'background-image':'url('+ getProfile()+'),url(assets/icon.png)'}">
                    <label class="green_box" for="file-input">
                      <i class="fa fa-pencil pencil_icn" aria-hidden="true"></i> 
                      <input id="file-input" (change)="preview_banner($event.target.files)" type="file" />
                    </label>
                  </div>

                  <label class="title_text">{{util.userInfo.first_name +' ' +util.userInfo.last_name}}</label>

                  <div class="row">
                    <div class="col-lg-6">
                      <label class="hint_lbl">{{util.translate('First Name')}}</label>
                      <input mdbInput type="text" [disabled]="!editProfileClick" id="form1" class="form-control"
                        [(ngModel)]="first_name">
                    </div>
                    <div class="col-lg-6">
                      <label class="hint_lbl">{{util.translate('Last Name')}}</label>
                      <input mdbInput type="text" [disabled]="!editProfileClick" id="form1" class="form-control"
                        [(ngModel)]="last_name">
                    </div>
                    <div class="col-lg-6">
                      <label class="hint_lbl">{{util.translate('Gender')}}</label>
                      <select [(ngModel)]="gender" [disabled]="!editProfileClick" class="form-control form-select" type="text" required
                        style="border: 1px solid lightgray;width: 100%;">
                        <option value="1"> {{util.translate('Male')}}</option>
                        <option value="0"> {{util.translate('Female')}} </option>
                        <option value="2"> {{util.translate('Others')}} </option>
                      </select>
                    </div>
                    <div class="col-lg-6">
                      <label class="hint_lbl">{{util.translate('Phone Number')}}</label>
                      <input mdbInput type="text" [disabled]="true" id="form1" class="form-control"
                        [(ngModel)]="mobile">
                    </div> 

                    <div class="col-lg-12" style="text-align: center;" *ngIf="editProfileClick">
                      <button mdbBtn type="button" class="btn btn-primary" (click)="updateProfile()"
                        mdbWavesEffect>{{util.translate('Save')}}</button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
      </div>
      <div *ngIf="tabId ==='order'">
        <div class="row">
          <div class="col-lg-6" *ngFor="let item of myOrders | paginate: {id:'pagin1', itemsPerPage:5, currentPage: page };let i = index;">
            <div class="card_div" >  
                <!-- (click)="goToHistoryDetail(item.orderId)" -->

              <div class="resto_detail">
                <div class="back_image"
                  [ngStyle]="{'background-image':'url('+api.mediaURL+item.str_cover+'),url(assets/placeholder.jpg)'}">
                </div>
                <div style="margin-left: 20px;display: flex;flex-direction: column;">
                  <label class="res_name" style="margin: 0px;">{{item.str_name}}</label>
                  <label class="res_location" style="margin: 0px;">
                    {{item.str_address}}
                  </label>
                </div>
              </div>

              <div class="line_div"></div>
              <div class="order_detail">
                
                <div class="small_lbl">
                  <span *ngFor="let order of item.orders;let ol = index">
                    <label class="head_gray">{{util.translate('Order No #')}}{{item.id}}</label>
                    <div *ngIf="!order.selectedItem || !order.selectedItem.length" class="border_bottom">
                      {{order.name}}
                    </div>

                    <div *ngFor="let subItems of order.selectedItem;let j = index" class="subNames">
                      <label class="food_title">{{order.name}} X {{order.selectedItem[j].total}}</label>
                      <div *ngFor="let addods of subItems.item" class="flex_titles">
                        <p class="sub_name">
                          - {{addods.name}}
                        </p>
                        <p class="sub_name" *ngIf="util.cside ==='left'">
                          {{util.currecny}} {{addods.value}}
                        </p>
                        <p class="sub_name" *ngIf="util.cside ==='right'">
                          {{util.currecny}} {{addods.value}}
                        </p>
                      </div>
                    </div>
                  </span>
                </div>
                <label class="head_gray">{{util.translate('ORDERED ON')}}</label>
                <label class="small_lbl">{{getDate(item.time)}} </label>
                <label class="head_gray">{{util.translate('TOTAL AMOUNT')}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.total}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.total}} {{util.currecny}} </label>
                <label class="head_gray">{{util.translate('Discount Amount')}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.discount}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.discount}} {{util.currecny}}</label>
                <label class="head_gray">{{util.translate('Net Payable Amount')}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.total-item.discount}}</label>
                <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.item.total-item.discount}} {{util.currecny}}</label>
                
              </div>

              <div class="line_div"></div>

              <div class="status_detail" *ngIf="item.status =='completed' || item.status == 'delivered'">
                <div class="deliver_div">
                  {{util.translate('Delivered')}}
                </div>
              </div>
              <div class="status_detail" *ngIf="item.status =='rejected' || item.status == 'cancel'">
                <div class="deliver_div">
                  {{util.translate('Your Order is')}} {{item.status}}
                </div>
              </div>
              <div class="status_detail" *ngIf="item.status =='success'">
                <div class="deliver_div">
                  {{util.translate('Your Appointment Ref.')}} {{item.extra_field}} <br>Date:{{item.sdate}} <br>Time :{{item.stime}}
                </div>
              </div>
              <div class="status_detail"
                *ngIf="item.status =='created' || item.status =='ongoing' || item.status === 'accepted'">
                <div class="deliver_div">
                  {{util.translate('Your Order is')}} {{item.status}}
                </div>

                <div class="repeat_div" style="color: green;">
                  <i class="fa fa-location-arrow" color="primary"></i> {{util.translate('Appointment Schedule:')}} : {{item.sdate}} {{item.stime}}
                </div>

                <!-- <div class="repeat_div" style="color: green;">
                  <mdb-icon fas icon="location-arrow" color="primary"></mdb-icon> {{util.translate('Track Order')}}
                </div> -->
              </div>
            </div>
            

            <div *ngFor="let item of dummy" class="card_div">
              <div class="resto_detail">
                <div class="back_image">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ 'border-radius': '5px', height: '50px',width:'100%'}">
                  </ngx-skeleton-loader>
                </div>
                <div style="margin-left: 20px;display: flex;flex-direction: column;">
                  <label class="res_name" style="margin: 0px;">
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'120px'}">
                    </ngx-skeleton-loader>
                  </label>
                  <label class="res_location" style="margin: 0px;">
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered" *ngIf="myOrders?.length ">
            <pagination-controls id="pagin1" (pageChange)="page = $event"></pagination-controls>
          </div>
        </div>
      </div>

      <div *ngIf="tabId ==='address'">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 newAddress_card" (click)="addNewAddress()">
            <i class="fa fa-plus-circle"></i>
            <label class="lbl">{{util.translate('Add New')}}</label>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 address_card" *ngFor="let item of myaddress">
            <p class="title">{{item.name}} </p>
            <p class="full_address">{{item.title}} - {{item.house}} {{item.landmark}} {{item.address}}</p>
            <div class="btns">
              <label class="editBtn" (click)="editAddress(item)" mdbWavesEffect>{{util.translate('Edit')}}</label>
              <!-- <label class="delete" (click)="deleteAddress(item)" mdbWavesEffect>{{util.translate('Delete')}}</label> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 address_card" *ngFor="let item of dummyAddress">
            <p class="title">
              <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
            </p>
            <p class="full_address">
              <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'160px'}"> </ngx-skeleton-loader>
            </p>
            <div class="btns">
              <label class="editBtn" mdbWavesEffect>
                <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
              </label>
              <label class="delete" mdbWavesEffect>
                <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="tabId ==='report'">
        <div class="row" *ngIf="BtnGetOTP">
          <button mdbBtn class="round_btn" (click)="goToGetOTP()">{{util.translate('Get OTP')}}</button>
          

        </div>
        <div class="row"  *ngIf="BtnVerifyOTP" >
          <input type="number" [(ngModel)]="OTPVal" >
          <button mdbBtn class="round_btn" (click)="VerifyGetOTP()">{{util.translate('Verify OTP')}}</button>
          

        </div>

        <!-- <div class="row"  > 
          <button mdbBtn class="round_btn" (click)="SyncCTP()">{{util.translate('Sync CTP')}}</button>
          

        </div> -->

        <div class="row header"> 
            <div class="col-2 col-md-2" >Sl</div>
            <div class="col-5 col-md-5" >Date</div>
            <div class="col-5 col-md-5" >Download</div>
         

          <div class="row" style="padding: 15px;"  *ngFor="let items of UsersReportData;let ol = index">
            <div class="col-2 " >{{ol+1}}</div>
            <div class="col-5 " >{{items.sys_created}}</div>
            <div class="col-5" ><button class="btn primary" (click)="GetDownloadReport(items.bill_id)">Download </button></div>
              
          </div>

           
          
        </div>
      </div>


    </div>
  </div>
</div>
